import React from "react";

import {
  Nav,
  Navbar,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  render() {
    return (
      <div>
        <div id="header">
          <img
            src="/images/header-image.jpg"
            alt="WBA Analytical Laboratories"
            width="960"
            height="222"
          />
        </div>
        <Navbar color="light" light expand="md">
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/">HOME</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us/">About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/quality-confidence/">
                Quality &amp; Confidence
              </NavLink>
            </NavItem>
            <UncontrolledDropdown
              nav
              inNavbar
              onMouseOver={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle nav caret>
                Service
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="div">
                  <NavLink href="/service/chemistry-testing/">
                    Chemistry Testing
                  </NavLink>
                </DropdownItem>
                <DropdownItem tag="div">
                  <NavLink href="/service/microbiology-testing/">
                    Microbiology Testing
                  </NavLink>
                </DropdownItem>
                <DropdownItem tag="div">
                  <NavLink href="/service/research-services/">
                    Research Services
                  </NavLink>
                </DropdownItem>
                <DropdownItem tag="div">
                  <NavLink href="/service/technical-services">
                    Technical Services
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/samples-reports/">Samples &amp; Reports</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact-us">Contact Us</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default Header;
