import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { upperFirst } from "lodash";
import Header from "./Header";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/global.css";
import "../styles/header.css";
import Notification from "./Notification/Notification";

const TemplateWrapper = ({
  pageTitle,
  metaTitle,
  metaDescription,
  sidebarImageUrl,
  sidebarImageAlt,
  children
}) => {
  const title = metaTitle ? metaTitle : pageTitle ? upperFirst(pageTitle) : "";

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Helmet
            htmlAttributes={{
              lang: "en"
            }}
            title={`${title ? title + " | " : ""}${
              data.site.siteMetadata.title
            }`}
            meta={[
              { name: "description", content: metaDescription },
              { name: "viewport", content: "width=960" }
            ]}
          ></Helmet>

          <div id="container">
            <Header />

            <div id="sidebar1">
              <img
                src={sidebarImageUrl}
                alt={sidebarImageAlt}
                width="225"
                height="450"
              />
            </div>
            {children}
            <br className="clearfloat" />

            <div id="footer">
              <p>
                <a href="/">
                  <img
                    style={{ verticalAlign: "middle" }}
                    alt="WBA Labs"
                    src="/images/wba-footer-logo.jpg"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&copy; 2019 WBA Analytical Laboratories,
                Inc. |&nbsp;<a href="/privacy-policy">Privacy Policy</a> |{" "}<a href="/contact-us">Contact Us</a> |{" "}
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </p>
            </div> 
             <Notification
              className={"notification--gdpr"}
              text="Your privacy is important to us. We use cookies to ensure you get the best experience on this website.
              For more information about how to control your cookies click&nbsp;"
              hyperlinkText={"learn more."}
              learnMoreLink={"/privacy-policy/"}
              buttonText={"Accept"}
            />
          </div>
        
        </React.Fragment>
      )}
    />
  );
};

export default TemplateWrapper;
